import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import './Cards.scss';

const Cards = (props) => {
  return (
    <div className="columns is-multiline card-grid">
      {(props.gridItems || []).map(item => (
        <div key={item.id} className="column grid-item is-one-third">
          <div className={'card' + `${props.hideBorder ? ' hideBorder' : ''}`}>
            <div
              className={props.size ? `card-image-${props.size}` : 'card-image'}
            >
              <img alt="smooper" src={item.image} />
            </div>
            <div className="card-content">
              <div className="title is-6">{item.text}</div>
              <div className="content">{item.subtitle}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;